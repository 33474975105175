// import $ from 'jquery';
import Swiper from 'swiper/bundle';
// import barba from '@barba/core';
// import 'swiper/css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isSmartPhone } from "./modules/util.js";

// gsap scrollTrigerr
gsap.registerPlugin(ScrollTrigger);

const slider1 = document.querySelector('.js-slider1');
const pickupSlider = document.querySelector('.js-pickup-slider');
const initSwiperPc = () => {
  if (slider1) {
    new Swiper(slider1, {
      centeredSlides: true,
      loop: true,
      speed: 500,
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
          delay: 5000,
      },
      breakpoints: {
        768: {
          spaceBetween: 40,
          slidesPerView: 1.65,
        }
      },
      pagination: {
        el: slider1.querySelector('.swiper-pagination'),
        clickable: true,
      },
      navigation: {
        nextEl: slider1.querySelector('.swiper-button-next'),
        prevEl: slider1.querySelector('.swiper-button-prev'),
      },
    });
  }
  if (pickupSlider) {
    new Swiper(pickupSlider, {
      loop: false,
      speed: 500,
      slidesPerView: 2.2,
      spaceBetween: 0,
      autoplay: {
          delay: 5000,
      },
      breakpoints: {
        768: {
          spaceBetween: 60,
          slidesPerView: 2.2,
        }
      },
    });
  }
  // pcSwiperList = [];
  // for (pcSwiperEl of pcSwiperEls) {
  //   let space = pcSwiperEl.getAttribute("data-slider-space") || 20;
  //   pcSwiperList.push(new Swiper(pcSwiperEl, {
  //     slidesPerView: 'auto',
  //     loop: true,
  //     speed: 1000,
  //     spaceBetween: Number(space),
  //     // autoplay: {
  //     //   delay: 4000,
  //     //   disableOnInteraction: false,
  //     // },
  //     loopedSlides: pcSwiperEl.querySelectorAll('.swiper-slide').length,
  //     pagination: {
  //       clickable: true,
  //       el: pcSwiperEl.querySelector('.swiper-pagination')
  //     },
  //     navigation: {
  //       nextEl: pcSwiperEl.querySelector('.swiper-button-next'),
  //       prevEl: pcSwiperEl.querySelector('.swiper-button-prev'),
  //     },
  //   }));
  // }
}
initSwiperPc()
const destroySwiperPC = () => {
  if (pcSwiperList) {
    for (pcSwiper of pcSwiperList) {
      pcSwiper.destroy(false, true);
    }
  }
};





if (!isSmartPhone()) {
  /*
  var $menu = $('.js-drw-items'),
      $item = $('.js-drw-item'),
      w = $(window).width(), //window width
      h = $(window).height(); //window height
  
  $(window).on('mousemove', function(e) {
    var offsetX = 0.5 - e.pageX / w, //cursor position X
        offsetY = 0.5 - e.pageY / h, //cursor position Y
        dy = e.pageY - h / 2, //@h/2 = center of poster
        dx = e.pageX - w / 2, //@w/2 = center of poster
        theta = Math.atan2(dy, dx), //angle between cursor and center of poster in RAD
        angle = theta * 180 / Math.PI - 90, //convert rad in degrees
        offsetPoster = $menu.data('offset') || 10,
        transformPoster = 'translate3d(0, ' + -offsetX * offsetPoster + 'px, 0) rotateX(' + (-offsetY * offsetPoster) + 'deg) rotateY(' + (offsetX * (offsetPoster * 2)) + 'deg)'; //poster transform
  
    //get angle between 0-360
    if (angle < 0) {
      angle = angle + 360;
    }
  
    //poster transform
    $menu.css('transform', transformPoster);
  
    //parallax for each layer
    $item.each(function() {
      var $this = $(this),
          offsetLayer = $this.data('offset') || 0,
          transformLayer = 'translate3d(' + offsetX * offsetLayer + 'px, ' + offsetY * offsetLayer + 'px, 20px)';
  
      $this.css('transform', transformLayer);
    });
  });
  */
}



let spSwiperList1 = [];
const spSwiperEls1 = document.querySelectorAll(".js-sp-swiper1");
const initSwiperSp = () => {
  if (spSwiperEls1 && spSwiperEls1.length !== 0) {
    for (spSwiper of spSwiperEls1) {
      let space = spSwiper.getAttribute('data-swiper-space') || 20;
      let loop = spSwiper.getAttribute('data-swiper-loop') || true;
      let speed = spSwiper.getAttribute('data-swiper-speed') || 1000;
      let perview = spSwiper.getAttribute('data-swiper-per-view') || 'auto';
      let pagenationTarget = spSwiper.getAttribute('data-swiper-pagination')
      let slideCount = spSwiper.querySelectorAll('.swiper-slide').length;
      let pagenationEl = pagenationTarget ? document.querySelector(pagenationTarget) : spSwiper.querySelector('.swiper-pagination');
      let pagenation = (pagenationEl) ? {
        el: pagenationEl,
        clickable: true,
      } : false;

      spSwiperList1.push(new Swiper(spSwiper, {
        loop: false,
        slidesPerView: perview,
        spaceBetween: Number(space),
        pagination: pagenation,
        speed: speed,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          init: function () {
            spSwiper.classList.add("slide-count-" + slideCount)
            // if (slideCount<=1) {
            //   spSwiper.querySelectorAll(".swiper-button-navi").style.display ="none"
            // }
          },
        },
      }));
    }
  }
}

const destroySwiperSp = () => {
  if (spSwiperList1.length != 0) {
    for (spSwiper of spSwiperList1) {
      spSwiper.destroy(false, true);
    }
    spSwiperList1 = [];
  }
}

let timeoutID = 0;
let dd = 500;
$(window).on('resize', function () {
  clearTimeout(timeoutID);
  timeoutID = setTimeout(() => {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {

    } else {
      // メディアクエリにマッチしたらスライダーを初期化
      if (isSmartPhone() && spSwiperList1.length == 0) {
        initSwiperSp();
      } else {
        destroySwiperSp();
      }
    }
  }, dd);
});
document.addEventListener('DOMContentLoaded', () => {
  if (spSwiperEls1 && isSmartPhone()) {
    initSwiperSp();
  } else {
    destroySwiperSp();
  }
})