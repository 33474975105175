const isSmartPhone = () => {
    // if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
    if (window.matchMedia && window.innerWidth < 768) {
        return true;
    } else {
        return false;
    }
};

/**
* @desc 一文字づつ文字をラップする
* @param {*} target 
* @returns 一文字つづspanでラップしたテキスト
*/
const spanWrapText = (target) => {
    const nodes = [...target.childNodes];
    let returnText = '';

    for (const node of nodes) {
        if (node.nodeType == 3) {
            const text = node.textContent.replace(/\r?\n/g, '');
            const splitText = text.split('');
            for (const char of splitText) {
                returnText += `<span>${char}</span>`;
            }
        } else {
            returnText += node.outerHTML;
        }
    }
    return returnText;
};
const delay = function  (t) {
    t = t || 2000;
    return new Promise((done) => {
      setTimeout(() => {
        done();
      }, t);
    });
}

export { isSmartPhone, spanWrapText, delay }